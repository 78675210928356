import { blackColor, container } from 'assets/jss/material-kit-pro-react.js';
import {
	textSmall,
	titleSmall,
	lineHeightMedium,
	bold,
	primary,
	black,
	actionLink
} from 'assets/jss/amateur/components/classicPageHeaderStyle';

const gridStyle = (theme) => ({
	container,
	textSmall,
	titleSmall,
	lineHeightMedium,
	bold,
	primary,
	black,
	actionLink,
	gridContainer: {
		marginRight: '-12px',
		marginLeft: '-12px',
		width: 'auto'
	},
	gridItem: {
		position: 'relative',
		width: '100%',
		minHeight: '1px',
		paddingRight: '12px',
		paddingLeft: '12px'
		/* flexBasis: "auto" */
	},

	textCenter: {
		textAlign: 'center'
	},

	webcamsGrid: {
		paddingLeft: '9.5px',
		paddingRight: '9.5px'
	},

	spacingTop3: {
		marginTop: theme.spacing(3)
	},

	spacingBottom1: {
		marginBottom: theme.spacing(1)
	},

	separator: {
		marginLeft: theme.spacing(1) / 2,
		marginRight: theme.spacing(1) / 2,
		color: blackColor,
		fontSize: '16px'
	}
});

export default gridStyle;
