import {
    defaultFont,
    primaryColor,
    blackColor,
    successColor,
    graysPalette,
    whiteColor,
    privatesPalette,
    hexToRgb,
    container
} from 'assets/jss/material-kit-pro-react.js';

const textSmall = {
    fontSize: '14px'
};
const textMedium = {
    fontSize: '16px'
};
const textLarge = {
    fontSize: '18px'
};
const titleSmall = {
    fontSize: '22px'
};
const titleMedium = {
    fontSize: '26px'
};
const titleLarge = {
    fontSize: '30px'
};
const titleXLarge = {
    fontSize: '36px'
};

const lineHeightSmall = {
    lineHeight: 1
};
const lineHeightMedium = {
    lineHeight: 1.375
};
const lineHeightLarge = {
    lineHeight: 1.8
};

const bold = {
    fontWeight: 'bold'
};
const primary = {
    color: primaryColor[0]
};
const black = {
    color: blackColor
};
const gray = {
    color: graysPalette['grayCC']
};
const white = {
    color: whiteColor
};

const actionLink = {
    fontSize: '14px',
    lineHeight: 1.375,
    color: blackColor,
    textDecoration: 'underline',

    '&:hover, &:focus': {
        color: blackColor
    }
};

const classicPageHeaderStyle = (theme) => ({
    container: {
        ...container
    },
    center: {
        textAlign: 'center'
    },
    titleXLarge: {
        ...titleXLarge
    },
    titleLarge: {
        ...titleLarge
    },
    titleMedium: {
        ...titleMedium
    },
    titleSmall: {
        ...titleSmall
    },
    textLarge: {
        ...textLarge
    },
    textMedium: {
        ...textMedium
    },
    textSmall: {
        ...textSmall
    },
    lineHeightSmall: {
        ...lineHeightSmall
    },
    lineHeightMedium: {
        ...lineHeightMedium
    },
    lineHeightLarge: {
        ...lineHeightLarge
    },
    bold: {
        ...bold
    },
    uppercase: {
        textTransform: 'uppercase'
    },
    primary: {
        ...primary
    },
    black: {
        ...black
    },
    white: {
        ...white
    },
    gray: {
        ...gray
    },
    spacingTop3: {
        marginTop: theme.spacing(3)
    },
    spacingTop2: {
        marginTop: theme.spacing(2)
    },
    spacingBottom2: {
        marginBottom: theme.spacing(2)
    },
    spacingBottom1: {
        marginBottom: theme.spacing(1)
    },
    actionLink: {
        ...actionLink
    },
    separator: {
        marginLeft: theme.spacing(1) / 2,
        marginRight: theme.spacing(1) / 2,
        ...black,
        fontSize: '16px'
    }
});

export default classicPageHeaderStyle;

export {
    textSmall,
    textMedium,
    textLarge,
    titleSmall,
    titleMedium,
    titleLarge,
    titleXLarge,
    lineHeightSmall,
    lineHeightMedium,
    lineHeightLarge,
    bold,
    primary,
    black,
    gray,
    white,
    actionLink
}
