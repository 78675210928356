const MOBILEDEVICETYPE = 'mobile';
const TABLETDEVICETYPE = 'tablet';
const SMARTTVDEVICETYPE = 'smarttv';
const WEARABLEDEVICETYPE = 'wearable';
const CONSOLEDEVICETYPE = 'console';
const DESKTOPDEVICETYPE = 'desktop';
export {
	MOBILEDEVICETYPE,
	TABLETDEVICETYPE,
	DESKTOPDEVICETYPE,
	SMARTTVDEVICETYPE,
	WEARABLEDEVICETYPE,
	CONSOLEDEVICETYPE
};
