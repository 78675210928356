const PERFORM_CHAT_AUTOSCROLL_IF_INTENDED = 'performChatAutoScrollIfIntended';
const FORCE_LOGOUT_AND_OPEN_LOGIN_POPUP = 'forceLogoutAndLogin';

const OPEN_LOGIN_POPUP = 'openLogin';
const OPEN_MAIL_VERIFY_POPUP = 'openMailVerify';
const OPEN_BUYCOINS_POPUP_NO_TRACKING_DETAILS = 'openBuyCoins';
const OPEN_BUYCOINS_POPUP_WITH_TRACKING_DETAILS = 'openBuyCoinsWithTrackingDetail';
const OPEN_BUY_COINS_ON_WEBCAM = 'openBuyCoinsOnCam';

const OPEN_BUY_COINS_OR_FIRST_RECHARGE_MODAL_BASED_ON_PROMOSTARGET_NEEDSTRACKINGSEPARATELY =
	'openBuyCoinsOrFirstBuyModal';

const REQUEST_INITIAL_CHAT_MESSAGES_BASED_ON_CAM_STATE =
	'requestInitialChatMessagesBasedOnCamState';

const OPEN_SUGGESTED_REGISTER_POPUP = 'openSuggestedRegister';

const FORCE_VIDEO_RECONNECT = 'forceVideoReconnect';
const FORCE_VIDEO_RECONNECT_IF_MP4_DEV = 'forceMp4VideoReconnect';
const OPEN_REGISTER_POPUP = 'openRegister';
const FOLLOW_CURRENT_CAM = 'followCurrentCam';
const UNFOLLOW_CURRENT_CAM = 'unfollowCurrentCam';
const CREATE_PETITION_IN_CURRENT_CAM = 'createPetitionInCurrentCam';
const ADD_INFO_MESSAGE_TO_CHAT = 'addInfoMessageToChat'; // window.dispatchEvent(new CustomEvent('addInfoMessageToChat',{detail:'test'}));
const GIVE_COINS_TO_CURRENT_CAM_ACTION = 'giveCoins';
const ADD_IFRAME_TO_CHAT = 'addIframeToChat';

const ADD_CUSTOM_MESSAGE_TO_CHAT = 'addCustomMessageToChat'; // window.dispatchEvent(new CustomEvent('addInfoMessageToChat',{detail:'test'}));

const HIDE_CHAT_IFRAME_BASED_ON_URL = 'hideChatIframeBasedOnUrl';
const ADD_INITIAL_CUSTOM_MESSAGES_TO_CHAT = 'addInitialCustomMessagesToChat'; // window.dispatchEvent(new CustomEvent('addInfoMessageToChat',{detail:'test'}));
const ADD_INITIAL_MESSAGES_WHEN_STARTING_NORMAL_PRIVATE =
	'addInitialMessagesWhenStartingNormalPrivate';
const ADD_INITIAL_MESSAGES_WHEN_STARTING_EXCLUSIVE_PRIVATE =
	'addInitialMessagesWhenStartingExclusivePrivate';
const ADD_INITIAL_MESSAGES_WHEN_MUTATING_TO_EXCLUSIVE_PRIVATE =
	'addInitialMessagesWhenMutatingToExclusivePrivate';

const OPEN_SPECIFIC_USER_PROFILE_POPUP = 'openUserProfile'; // window.dispatchEvent(new CustomEvent('openUserProfile',{detail:'username'}));
const OPEN_BUY_COINS_SUGGESTION = 'openBuyCoinsSuggestionInChatViewer'; // window.dispatchEvent(new CustomEvent('openUserProfile',{detail:'username'}));

const ADD_MESSAGE_WITH_END_TIP_BUTTON_TO_CHAT = 'addMessageWithEndTipButton'; // window.dispatchEvent(new CustomEvent('addMessageWithEndTipButton',{message:'test', buttonText: 'Aporta 10 monedicas'}));

const ADD_FOLLOWUNFOLLOWED_MESSAGE_TO_CHAT = 'addFollowUnfullowedMessage';
const OPEN_CUSTOM_ALERT = 'openCustomAlert'; // window.dispatchEvent(new CustomEvent('openCustomAlert',{detail:'text'}));
const OPEN_CUSTOM_CONFIRMATION = 'openCustomConfirmation'; // window.dispatchEvent(new CustomEvent('openCustomConfirmation',{detail: {customConfirmationTitle,customConfirmationContent,customConfirmationUrl}));
const OPEN_CONFIRM_REDIRECT = 'openConfirmRedirect'; // window.dispatchEvent(new CustomEvent('openRedirectConfirmation',{detail: {pathname,location}));
const CLOSE_FIXED_BOTTOM_BANNER = 'closeFixedBottomBanner';
const CLOSE_CUSTOM_IFRAME_POPUP = 'closeCustomIframe';
const RESIZE_BANNER_BASED_ON_URL = 'resizeAd';
const CLOSE_TOP_BANNER = 'closeTopBanner';
const CLOSE_IFRAME_BANNER_BASED_ON_URL = 'closeIframeBannerBasedOnUrl';

const REDIRECTTOURL = 'redirectToUrl';

const REWARD_ACHIEVED = 'rewardAchieved';
const OPEN_VIDEOS_ON_DEMAND = 'openVideosOnDemand';

const LOGOUT = 'logout';

export {
	CLOSE_CUSTOM_IFRAME_POPUP,
	OPEN_VIDEOS_ON_DEMAND,
	RESIZE_BANNER_BASED_ON_URL,
	PERFORM_CHAT_AUTOSCROLL_IF_INTENDED,
	REDIRECTTOURL,
	GIVE_COINS_TO_CURRENT_CAM_ACTION,
	CLOSE_FIXED_BOTTOM_BANNER,
	ADD_INITIAL_CUSTOM_MESSAGES_TO_CHAT,
	OPEN_LOGIN_POPUP,
	OPEN_CUSTOM_ALERT,
	OPEN_CUSTOM_CONFIRMATION,
	OPEN_CONFIRM_REDIRECT,
	OPEN_REGISTER_POPUP,
	OPEN_BUYCOINS_POPUP_NO_TRACKING_DETAILS,
	CLOSE_IFRAME_BANNER_BASED_ON_URL,
	ADD_CUSTOM_MESSAGE_TO_CHAT,
	FOLLOW_CURRENT_CAM,
	UNFOLLOW_CURRENT_CAM,
	CREATE_PETITION_IN_CURRENT_CAM,
	ADD_INFO_MESSAGE_TO_CHAT,
	OPEN_MAIL_VERIFY_POPUP,
	OPEN_SPECIFIC_USER_PROFILE_POPUP,
	OPEN_BUY_COINS_OR_FIRST_RECHARGE_MODAL_BASED_ON_PROMOSTARGET_NEEDSTRACKINGSEPARATELY,
	ADD_FOLLOWUNFOLLOWED_MESSAGE_TO_CHAT,
	FORCE_LOGOUT_AND_OPEN_LOGIN_POPUP,
	CLOSE_TOP_BANNER,
	REQUEST_INITIAL_CHAT_MESSAGES_BASED_ON_CAM_STATE,
	REWARD_ACHIEVED,
	OPEN_BUYCOINS_POPUP_WITH_TRACKING_DETAILS,
	OPEN_BUY_COINS_ON_WEBCAM,
	HIDE_CHAT_IFRAME_BASED_ON_URL,
	OPEN_BUY_COINS_SUGGESTION,
	ADD_IFRAME_TO_CHAT,
	FORCE_VIDEO_RECONNECT_IF_MP4_DEV,
	OPEN_SUGGESTED_REGISTER_POPUP,
	FORCE_VIDEO_RECONNECT,
	ADD_INITIAL_MESSAGES_WHEN_STARTING_NORMAL_PRIVATE,
	ADD_INITIAL_MESSAGES_WHEN_STARTING_EXCLUSIVE_PRIVATE,
	ADD_INITIAL_MESSAGES_WHEN_MUTATING_TO_EXCLUSIVE_PRIVATE,
	LOGOUT,
	ADD_MESSAGE_WITH_END_TIP_BUTTON_TO_CHAT
};
